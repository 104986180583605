<template>
    <div class="flexPage">
        <navbar :params="pageInfo" />
        <div class="notice-boxes">
            <div class="list" v-for="item in noticeVO" :key="item.id">
                <div class="notice-title">
                    <img src="../assets/img/notices.png" alt="" style="width: 32px;height: 32px;margin-right: 8px">
                    {{ item.title }}
                </div>
                <div class="font-content">{{ formattedData(item.content) }}</div>
                <div class="font-content">{{ item.createTime }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import navbar from '@/views/modules/navbar.vue';
export default {
    data() {
        return {
            pageInfo: {
                customTitle: this.$t('other.translate93'),
                border: false,
                navbarStyle: '#fff',
                backname: 'home'
            },
            noticeVO: [],
        }
    },
    components: { navbar },
    mounted() {
        this.getNotice();
    },
    methods: {
        // 获取公告
        getNotice() {
            this.$http.post(this.host + '/uc/news/page', {}).then((response) => {
                var resp = response.body;
                if (resp.code !== 0) return;
                this.noticeVO = resp.data.content;
            });
        },
        // 删除<font>标签并格式化数据
        formattedData(content) {
            // 使用正则表达式删除<font>标签
            const cleanedData = content.replace(/<font[^>]*>/g, '').replace(/<\/font>/g, '');
            return cleanedData;
        }
    }
}
</script>

<style>
.notice-boxes {
    padding: 0 5.3435vw;
    padding-top: 16px;
    background-color: #fff
}

.notice-boxes .list {
    margin-bottom: 6.3613vw;
    border-bottom: 1px solid #0000001A;
    padding-bottom: 24px;

}

.notice-boxes .list .notice-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    display: flex;
    align-items: center

}

.notice-boxes .list .font-content {
    font-size: 13px;
    font-weight: 500;
    color: #000000B2;
    margin-top: 12px
}
</style>